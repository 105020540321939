import * as types from "../actions/types";

const initialState = {
  skuToProcess: [],
  ChannelAdvisorAccountName: [],
};

export function formReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SKU_SINGLE:
      return {
        ...state,
        skuToProcess: [...state.skuToProcess, action.payload],
      };
    case types.ADD_SKU_BULK:
      return {
        ...state,
        skuToProcess: [...state.skuToProcess, ...action.payload],
      };

    case types.REMOVE_SKU_SINGLE:
      return {
        ...state,
        skuToProcess: state.skuToProcess.filter(
          (item) => action.payload !== item
        ),
      };

    case types.REMOVE_SKU_BULK:
      return {
        ...state,
        skuToProcess: state.skuToProcess.filter(
          (i) => action.payload.indexOf(i) === -1
        ),
      };

    case types.SET_ACCOUNT:
      return {
        ...state,
        ChannelAdvisorAccountName: [...action.payload],
      };
    default:
      return state;
  }
}
