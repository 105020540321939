import * as actions from "./actions";
import axios from "axios";

export const startExecution = () => async (dispatch, getState) => {
  const state = getState();
  const STATE_MACHINE_ARN =
    "arn:aws:states:ap-southeast-2:938767739537:stateMachine:neto-ca-image-sync";
  let input = [];
  state.form.skuToProcess.forEach((sku) => {
    state.form.ChannelAdvisorAccountName.forEach((account) => {
      input.push({
        sku: sku,
        ChannelAdvisorAccountName: account,
      });
    });
  });
  const requestData = {
    input: JSON.stringify(input),
    name: `${new Date().getUTCDate()}-${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`,
    stateMachineArn: STATE_MACHINE_ARN,
  };

  dispatch(actions.ExecutionStart());
  axios
    .post(
      "https://bvy4yp9s3d.execute-api.ap-southeast-2.amazonaws.com/production/execution",
      requestData
    )
    .then((res) => {
      if (res.data.message) {
        dispatch(actions.ExecutionFail(res.data.message));
      } else {
        const fetchRequestData = { executionArn: res.data.executionArn };
        const keepFetchingResult = setInterval(() => {
          axios
            .post(
              "https://bvy4yp9s3d.execute-api.ap-southeast-2.amazonaws.com/production/result",
              fetchRequestData
            )
            .then((res) => {
              if (res.data.status === "SUCCEEDED") {
                clearInterval(keepFetchingResult);
                dispatch(actions.ExecutionSuccess(res.data.output));
              }
            })
            .catch((err) => {
              clearInterval(keepFetchingResult);
              dispatch(actions.ExecutionFail(err));
            });
        }, 2000);
      }
    })
    .catch((err) => {
      dispatch(actions.ExecutionFail(err));
    });
};

export const addSingleSku = (sku) => async (dispatch) => {
  dispatch(actions.AddSingleSku(sku));
};

export const addBulkSku = (skuList) => async (dispatch) => {
  dispatch(actions.AddBulkSku(skuList));
};

export const removeSingleSku = (sku) => async (dispatch) => {
  dispatch(actions.RemoveSingleSku(sku));
};

export const removeBulkSku = (skuList) => async (dispatch) => {
  dispatch(actions.RemoveBulkSku(skuList));
};

export const setCAAccount = (accountName) => async (dispatch) => {
  dispatch(actions.setCAAccount(accountName));
};
