import * as actionTypes from "./types";

export function AddSingleSku(sku) {
  return {
    type: actionTypes.ADD_SKU_SINGLE,
    payload: sku,
  };
}

export function AddBulkSku(skuList) {
  return {
    type: actionTypes.ADD_SKU_BULK,
    payload: skuList,
  };
}

export function RemoveSingleSku(sku) {
  return {
    type: actionTypes.REMOVE_SKU_SINGLE,
    payload: sku,
  };
}

export function RemoveBulkSku(skuList) {
  return {
    type: actionTypes.REMOVE_SKU_BULK,
    payload: skuList,
  };
}

export function ExecutionStart() {
  return {
    type: actionTypes.EXECUTION_START,
  };
}

export function ExecutionSuccess(result) {
  return {
    type: actionTypes.EXECUTION_SUCCESS,
    payload: result,
  };
}

export function ExecutionFail(err) {
  return {
    type: actionTypes.EXECUTION_FAIL,
    payload: err,
  };
}

export function setCAAccount(accountName) {
  return {
    type: actionTypes.SET_ACCOUNT,
    payload: accountName,
  };
}
