import React from "react";
import "./StatusWindowStyle.scss";
import { useSelector } from "react-redux";

const spinGroup = (
  <div className="spinner-group">
    <div className="spinner-grow text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="spinner-grow text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="spinner-grow text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);
const StatusWindow = () => {
  const isRunning = useSelector((state) => state.execution.isRunning);
  const errorList = useSelector((state) => state.execution.error);

  return (
    <div className="status-window">
      <label>Running Status: </label>
      <hr />
      {isRunning && spinGroup}
      <ul className="list-group list-group-flush">
        {errorList &&
          errorList.map((notice) => (
            <li className="list-group-item" key={notice.body}>
              {notice.body}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default StatusWindow;
