import React from "react";
import "./SkuListStyle.scss";
import { useSelector } from "react-redux";

export const SkuList = () => {
  const skuList = useSelector((state) => state.form.skuToProcess);
  return (
    <div className="sku-list">
      <label>Sku(s) that will be processed: </label>
      <hr />
      <ul className="list-group list-group-flush">
        {skuList.map((sku, index) => (
          <li className="list-group-item" key={`${sku}${index}`}>
            {sku}
          </li>
        ))}
      </ul>
    </div>
  );
};
