import React, { useEffect, useState } from "react";
import "./MainFormStyle.scss";
import * as actionCreator from "../../actions/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ImageForm = () => {
  const [singleAddSku, setSingleAddSku] = useState("");
  const [singleRemoveSku, setSingleRemoveSku] = useState("");
  const [bulkAddFile, setBulkAddFile] = useState(null);
  const [ChannelAdvisorAccount, setChannelAdvisorAccount] = useState([]);
  const [bulkRemoveFile, setBulkRemoveFile] = useState(null);

  const dispatch = useDispatch();

  const isRunning = useSelector((state) => state.execution.isRunning);
  const SkuList = useSelector((state) => state.form.skuToProcess);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    setChannelAdvisorAccount(["USA"]);
  }, []);

  useEffect(() => {
    dispatch(actionCreator.setCAAccount(ChannelAdvisorAccount));
  }, [ChannelAdvisorAccount, dispatch]);

  const HandleSingleSkuClick = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "add":
        dispatch(actionCreator.addSingleSku(singleAddSku));
        break;
      case "delete":
        if (singleRemoveSku)
          dispatch(actionCreator.removeSingleSku(singleRemoveSku));
        break;
      default:
        return;
    }
  };
  const HandleBulkSkuClick = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "bulk-add":
        if (bulkAddFile) processBulkAdd();
        break;
      case "bulk-delete":
        if (bulkRemoveFile) processBulkDelete();
        break;
      default:
        return;
    }
  };

  const HandleSetCAAccountClick = (e) => {
    let Accounts = [];
    e.forEach((option) => {
      Accounts.push(option.value);
    });

    setChannelAdvisorAccount(Accounts);
  };

  const HandleProcessBtnClick = (e) => {
    e.preventDefault();
    dispatch(actionCreator.startExecution());
  };
  const processBulkAdd = () => {
    Papa.parse(bulkAddFile, {
      header: false,
      complete: (res) => {
        let data = [];
        // Papa will treat each line in csv file as an array
        // in csv we only have one column -> Sku
        res.data.forEach((lineArray) => {
          if (lineArray[0]) data.push(lineArray[0]);
        });
        dispatch(actionCreator.addBulkSku(data));
      },
    });
  };

  const processBulkDelete = () => {
    Papa.parse(bulkRemoveFile, {
      header: false,
      complete: (res) => {
        let data = [];
        // Papa will treat each line in csv file as an array
        // in csv we only have one column -> Sku
        res.data.forEach((lineArray) => {
          if (lineArray[0]) data.push(lineArray[0]);
        });
        dispatch(actionCreator.removeBulkSku(data));
      },
    });
  };

  const options = [
    { value: "USA", label: "USA" },
    { value: "AUSTRALIA", label: "Australia" },
    { value: "CANADA", label: "Canada" },
    { value: "AMAZONFRANCE", label: "Amazon France" },
    { value: "AMAZONGERMANY", label: "Amazon Germany" },
    { value: "AMAZONITALY", label: "Amazon Italy" },
    { value: "AMAZONSPAIN", label: "Amazon Spain" },
    { value: "AMAZONUK", label: "Amazon UK" },
  ];
  return (
    <form className="main-form">
      <div className="row mb-2">
        <label className="mb-3">Select ChannelAdvisor Account(s)</label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          defaultValue={[options[0]]}
          isMulti
          id="account-selector"
          onChange={(e) => {
            HandleSetCAAccountClick(e);
          }}
          options={options}
        />
        <small
          className={ChannelAdvisorAccount.length === 0 ? "select-tip" : "hide"}
        >
          A value is required
        </small>
      </div>
      <div className="row mb-2">
        <label className="mb-3">Add & Remove single Sku</label>
        <div className="col-8">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="add-sku"
              placeholder="xxxxxx"
              value={singleAddSku}
              onChange={(e) => setSingleAddSku(e.target.value)}
            />
            <label htmlFor="add-sku">Add Sku</label>
          </div>
        </div>
        <div className="col-4">
          <button
            type="button"
            onClick={(e) => HandleSingleSkuClick(e)}
            id="add"
            className="btn btn-outline-primary btn-adjust"
          >
            Add
          </button>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-8">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="remove-sku"
              placeholder="xxxxxx"
              value={singleRemoveSku}
              onChange={(e) => setSingleRemoveSku(e.target.value)}
            />
            <label htmlFor="remove-sku">Remove Sku</label>
          </div>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-outline-primary btn-adjust"
            onClick={(e) => HandleSingleSkuClick(e)}
            id="delete"
          >
            Remove
          </button>
        </div>
      </div>
      <hr />
      <div className="row mb-2">
        <label className="mb-3">
          Bulk Add & Remove Sku (CSV,No header line)
        </label>
        <div className="col-8">
          <div className="input-group mb-3">
            <input
              type="file"
              className="form-control btn-adjust"
              id="bulk-add-file"
              onChange={(e) => setBulkAddFile(e.target.files[0])}
              accept=".csv"
            />
          </div>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-outline-primary btn-adjust"
            id="bulk-add"
            onClick={(e) => HandleBulkSkuClick(e)}
          >
            Bulk add
          </button>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-8">
          <div className="input-group mb-3">
            <input
              type="file"
              className="form-control btn-adjust"
              id="bulk-delete-file"
              onChange={(e) => setBulkRemoveFile(e.target.files[0])}
              accept=".csv"
            />
          </div>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-outline-primary btn-adjust"
            id="bulk-delete"
            onClick={(e) => HandleBulkSkuClick(e)}
          >
            Bulk remove
          </button>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className={
              ChannelAdvisorAccount.length === 0
                ? "btn btn-outline-danger btn-adjust"
                : "btn btn-outline-success btn-adjust"
            }
            onClick={(e) => {
              HandleProcessBtnClick(e);
            }}
            disabled={
              isRunning ||
              ChannelAdvisorAccount.length === 0 ||
              SkuList.length === 0
            }
          >
            {ChannelAdvisorAccount.length === 0
              ? "Select ChannelAdvisor Account(s)"
              : SkuList.length === 0
              ? "Add Sku(s)"
              : "Process selected Sku(s)"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ImageForm;
