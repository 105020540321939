import * as types from "../actions/types";

const initialState = {
  error: [],
  isRunning: false,
};

export function executionReducer(state = initialState, action) {
  switch (action.type) {
    case types.EXECUTION_START:
      return {
        ...state,
        isRunning: true,
      };
    case types.EXECUTION_SUCCESS: {
      let result = JSON.parse(action.payload);
      return {
        ...state,
        isRunning: false,
        error: [...result],
      };
    }
    case types.EXECUTION_FAIL:
      return {
        ...state,
        isRunning: false,
        error: [`API error, contact admin \n ${action.payload}`],
      };
    default:
      return state;
  }
}
