import { combineReducers } from "redux";
import { executionReducer } from "./executionReducer";
import { formReducer } from "./formReducer";

const rootReducer = combineReducers({
  execution: executionReducer,
  form: formReducer,
});

export default rootReducer;
