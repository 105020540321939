import React from "react";
import MainForm from "../MainForm/MainForm";
import { SkuList } from "../SkuList/SkuList";
import StatusWindow from "../StatusWindow/StatusWindow";
import "./HomePageStyle.scss";

const HomePage = () => {
  return (
    <div className="App">
      <div className="container">
        <label className="title">Neto ChannelAdvisor Image Sync</label>
        <div className="row">
          <div className="col-4">
            <SkuList />
          </div>
          <div className="col-4">
            <MainForm />
          </div>
          <div className="col-4">
            <StatusWindow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
